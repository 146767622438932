// This file was autogenerated. Do not edit.
import React from 'react';
import { NavLink } from 'react-router-dom';

import './Nav.css';
import NavDrawer from './NavDrawer';


export default function Nav() {
  return (
    <NavDrawer>
      <div className="nav-container container-col">
        <NavLink to="/" className="submarines"><h2>TENTACLES</h2></NavLink>
        <>
  <h3>recursion-and-dynamic-programming</h3>
  <ul>
    <li>
  <NavLink to="/recursion-and-dynamic-programming/recursive-multiply">
    Recursive Multiply
  </NavLink>
</li>
  </ul>
</><>
  <h3>trees-and-graphs</h3>
  <ul>
    <li>
  <NavLink to="/trees-and-graphs/recover-bst">
    Recover Binary Search Tree
  </NavLink>
</li><li>
  <NavLink to="/trees-and-graphs/flatten">
    Flatten Binary Tree to Linked List
  </NavLink>
</li><li>
  <NavLink to="/trees-and-graphs/minimal-tree">
    Minimal Tree
  </NavLink>
</li><li>
  <NavLink to="/trees-and-graphs/median-of-two-sorted-arrays">
    Find Median of 2 Sorted Arrays
  </NavLink>
</li>
  </ul>
</>
        <h3>other-fun-things</h3>
        <ul>
          <li>
            <NavLink to="/binary-tree-playground">
              Binary Tree Playground
            </NavLink>
          </li>
        </ul>
      </div>
    </NavDrawer>
  );
}
