import React from "react";
import "HomePage.css";

import tentacleMonster from "tentacle-monster.png";

export default function HomePage() {
  return (
    <main className="home-main">
      <div className="home-content">
        <h1>Tentacles</h1>
        <div>
        <p>
          The mind is a tentacled monster that craves only some dense submarine to sink
          its limbs into.
        </p>
        </div>
        <p>Hungry?</p>
          <img
            className="tentacles"
            aria-label="spinning tentacle monster"
            src={tentacleMonster}
            width={1654 / 4}
            height={2227 / 4}
          />

      </div>
      <div className="home-credit">
        <p>
          Tentacle Monster by{" "}
          <a
            rel="noreferrer"
            href="https://www.deviantart.com/jaumbz-arts"
            target="_blank"
          >
            Jaumbz Arts
          </a>
        </p>
      </div>
    </main>
  );
}
