import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import SyntaxHighlighter from "./SyntaxHighlighter";

const components = {
  // eslint-disable-next-line no-unused-vars
  code: function Code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <SyntaxHighlighter
        language={match[1]}
        {...props}
      >
        {String(children).replace(/\n$/, "")}
      </SyntaxHighlighter>
    ) : (
      <code
        style={{
          backgroundColor: "#2F2F2F",
          paddingLeft: "3px",
          paddingRight: "3px",
          fontSize: "15px",
        }}
        className={className}
        {...props}
      >
        {children}
      </code>
    )
  },
  // TODO: Replace these if we switch to Styled Components
  // a: ({node, ...props}) => <Link color="secondary" {...props} />,
  // h1: ({node, ...props}) => <Typography variant="h1" {...props} />,
  // h2: ({node, ...props}) => <Typography component="h2" variant="h5" {...props} />,
  // h3: ({node, ...props}) => <Typography variant="h3" {...props} />,
  // h4: ({node, ...props}) => <Typography variant="h4" {...props} />,
  // h5: ({node, ...props}) => <Typography variant="h5" {...props} />,
  // h6: ({node, ...props}) => <Typography variant="h6" {...props} />,
  // p: ({node, ...props}) => <><Typography variant="body1" {...props} /><br /></>,
};

export default function Markdown({ children }) {
  return (
    <ReactMarkdown remarkPlugins={[gfm]} components={components}>
      {children}
    </ReactMarkdown>
  );
}
