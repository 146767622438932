import React from "react";

export default function BigO({ time, explanation, space }) {
  return (
    <>
      <h2>Big O Analysis</h2>
      <p>
        <strong>Time Complexity: </strong>O({time})
      </p>
      {space && (
        <p>
          <strong>Space Complexity: </strong>O({space})
        </p>
      )}
      <p>
        <strong>Explanation: </strong>
        {explanation}
      </p>
    </>
  );
}
