// This file was autogenerated. Do not edit.
export default async function apiCall(...inputs) {
    const response = await fetch(`https://tentacles-py.fly.dev/api/py/recursion-and-dynamic-programming/recursive-multiply`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputs)
    });
    const responseJSON = await response.json()

    return responseJSON.answer
}
