import React from "react";
import { Prism as ReactSyntaxHighlighter } from "react-syntax-highlighter";
import style from "./modifiedXonokaiTheme";

export default function SyntaxHighlighter({ children, language, ...props }) {
  return (
    <ReactSyntaxHighlighter
      style={style}
      language={language}
      {...props}
    >
      {children}
    </ReactSyntaxHighlighter>
  );
}
