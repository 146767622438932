import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import BinaryTreePlayground from "BinaryTreePlayground";
import HomePage from "HomePage";
import Nav from "Nav";
import Routes from "Routes";

export default function App() {
  return (
    <Router>
      <div className="flex full-width full-height">
        <Nav />
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/binary-tree-playground">
            <BinaryTreePlayground />
          </Route>
          <Routes />
        </Switch>
      </div>
    </Router>
  );
}
