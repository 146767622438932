// This file was autogenerated. Do not edit.
export default async function apiCall(...inputs) {
    const response = await fetch(`https://tentacles.fly.dev/api/go/trees-and-graphs/flatten`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({Root: { Root:  inputs[0] }})
    });
    const responseJSON = await response.json()

    return responseJSON.answer
}
