/**
 * @param {TreeNode} root
 * @return {TreeNode} The fixed tree.
 */
export default function recoverTree(root) {
    // Use the Morris Traversal to find bad nodes in O(n) time and 0(1) space
    // Morris Traversal uses a "Threaded Tree", where you modify the tree as you traverse it to save space.
    const inorder =[];
    const badNodes = []
    let cur = root
    while (cur) {
        if (cur.left === null) {
            visit(cur, inorder, badNodes)
            cur = cur.right
            continue
        }
        const prev = getPrev(cur)
        if (prev.right === null) {
            // This means it has not been threaded already
            prev.right = cur
            cur = cur.left
            continue
        }
        // We've hit a threaded node. Break the thread and go right
        prev.right = null
        visit(cur, inorder, badNodes)
        cur = cur.right
    }
    // Swap the two bad nodes.
    const firstBad = badNodes[0].val
    badNodes[0].val = badNodes[1].val
    badNodes[1].val = firstBad
    return root
}

/**
* Should only be called if node.left exists
* Returns the rightmost node of the left child, but not itself if it finds itself
*/
function getPrev(node) {
    // only call if `node.left` exists
    let cur = node.left
    while (cur.right && cur.right !== node) {
        cur = cur.right
    }
    return cur
}

/**
* Update inorder traversal and badNodes based on the next node.
*/
function visit(node, inorder, badNodes) {
    if (inorder.length === 0) {
        inorder.push(node)
        return
    } else if (inorder.length === 1) {
        inorder.push(node)
    } else {
        inorder[0] = inorder[1]
        inorder[1] = node
    }
    if (inorder[0].val > inorder[1].val) {
        // bad node found!
        if (badNodes.length === 0) {
            badNodes[0] = inorder[0]
        }
        badNodes[1] = inorder[1]
    }
}
