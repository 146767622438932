// This file was autogenerated. Do not edit.
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import RecursiveMultiplyPost from "posts/recursion-and-dynamic-programming/RecursiveMultiplyPost";
import RecoverBinarySearchTreePost from "posts/trees-and-graphs/RecoverBinarySearchTreePost";
import FlattenBinaryTreetoLinkedListPost from "posts/trees-and-graphs/FlattenBinaryTreetoLinkedListPost";
import MinimalTreePost from "posts/trees-and-graphs/MinimalTreePost";
import FindMedianof2SortedArraysPost from "posts/trees-and-graphs/FindMedianof2SortedArraysPost";


export default function Routes() {
  return (
    <Switch>
      <Route path="/recursion-and-dynamic-programming/recursive-multiply"><RecursiveMultiplyPost/></Route>
      <Route path="/trees-and-graphs/recover-bst"><RecoverBinarySearchTreePost/></Route>
      <Route path="/trees-and-graphs/flatten"><FlattenBinaryTreetoLinkedListPost/></Route>
      <Route path="/trees-and-graphs/minimal-tree"><MinimalTreePost/></Route>
      <Route path="/trees-and-graphs/median-of-two-sorted-arrays"><FindMedianof2SortedArraysPost/></Route>
    </Switch>
  )
}
