import React from "react";

export default function Hero({ title, prompt }) {
  return (
    <>
      <h1>{title}</h1>
      <p>
        <em>{prompt}</em>
      </p>
    </>
  );
}
