import "./Playground.css"

import React, { Suspense, useEffect, useState } from "react";
import { BinaryTree } from 'data-structures'

const Graphviz = React.lazy(() => import('graphviz-react'));

const gvOptions = {
  width: 400,
}

function inputsFromSignature(signature) {
  return signature.inputs.map(({ example }) => String(example))
}

export default function Playground({ solution, signature }) {
  const [results, setResults] = useState(<p>Submit to view results.</p>);
  const [userInputs, setUserInputs] = useState(inputsFromSignature(signature));

  useEffect(() => {
    setUserInputs(inputsFromSignature(signature));
  }, [signature]);

  async function handleSubmit(e) {
    e.preventDefault();
    let res = null;
    try {
      const args = userInputs.map((input, i) => {
        const inputMetadata = signature.inputs[i]
        switch (inputMetadata.type) {
          case "Array": {
            const elements = input.split(",");
            if (inputMetadata.array_type === "Number") {
              return elements.map((inpt) => {
                const num = Number(inpt);
                if (Number.isNaN(num)) {
                  throw new Error("Invalid input.");
                }
                return num;
              });
            }
            return elements;
          }
          case "Number": {
            return Number(input)
          }
          case "BinaryTree": {
            return BinaryTree.fromLevelOrder(input.split(",").map(el => {
              if (["null", "", null].includes(el)) {
                return null
              }
              if (inputMetadata.node_value_type === "Number") {
                return Number(el)
              }
              return el
            }))
          }
          default:
            return input;
        }
      });
      res = await solution(...args);
      switch (signature.output.type) {
        case "BinaryTree": {
          if (!(res instanceof BinaryTree)) {
            res = BinaryTree.fromObj(res);
          }
          setResults(<Graphviz dot={res.graphVizDOT} options={gvOptions} />);
          break;
        }
        default: {
          setResults(<p>{String(res)}</p>);
        }
      }
    } catch (error) {
      setResults(<p>{String(error)}</p>);
    }
  }

  return (
    <div className="container-col" style={{marginBottom: "50px"}}>
      <h2>Playground</h2>
      <div style={{
        display: "flex",
        justifyContent: "space-around"
      }}>
      <div style={{width: "45%"}}>
      <h3>Inputs</h3>
        <form onSubmit={handleSubmit}>
            {signature.inputs.map((input, i) => (
              <div
                key={`${input.name}-input`}
              >
                <label htmlFor={input.name} style={{fontSize: "18px", width: "100%"}}>
                  {input.name}
                  </label>
                  <input
                    style={{
                      width: "100%",
                      display: "block",
                      backgroundColor: "#000",
                      border: "1px dashed #008F11",
                      color: "#008F11",
                      padding: "5px",
                      fontSize: "16px",
                    }}
                    id={input.name}
                    value={userInputs[i]}
                    onChange={(e) => {
                      const inpts = [...userInputs];
                      inpts[i] = e.target.value;
                      setUserInputs(inpts);
                    }}
                  />
              </div>
            ))}
            <button
              style={{
                display: "block",
                backgroundColor: "hotpink",
                border: "none",
                fontSize: "20px",
                marginTop: "15px",
              }}
              type="submit"
            >
              Submit
            </button>
        </form>
      </div>
      <div style={{width: "45%"}}>
        <h3>Output</h3>
        <div className="results">
          <Suspense fallback={<p>Loading...</p>}>{results}</Suspense>
        </div>
      </div>
      </div>
    </div>
  );
}
