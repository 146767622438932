// You can check out the Binary Tree Playground to see how this works.
import { BinaryTree } from "data-structures";

function minimalTree(arr) {

  function inner(i, j) {
    if (i === j) {
      return null;
    }
    if ((j - i) === 1) {
      return new BinaryTree(arr[i], null, null);
    }
    const centreIndex = i + Math.floor((j - i) / 2);
    const left = inner(i, centreIndex);
    const right = inner(centreIndex + 1, j);
    return new BinaryTree(arr[centreIndex], left, right);
  }

  return inner(0, arr.length);

}

export default minimalTree;
